import {
  BlogPreview,
  InEvidence,
  LocationsOnMap,
  TalkedAboutUsLogos,
  B2BServices,
  QuickBuyButton,
  QuickTypeLinks,
  Hero,
  Features,
  BestSellers,
  ProductsReminder,
  DrawerQuickBuy,
  SiteGallery,
  ExclusiveGallery,
  ItinerantsGallery,
  Destinations,
  TagsCloud,
} from '@components';

import { NextSeo } from 'next-seo';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { stringify } from 'qs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useVisibility, useMediaQueries } from '@hooks';
import { userSelector } from '@slices';
import { DefaultLayout } from '@layouts';

import { useGetSitesByRegionQuery } from "@services";

const HomePage = ({ data, articles }) => {
  // Redux hooks
  const { logged, first_name } = useSelector(userSelector);
  const [isVisible] = useVisibility();
  const { isMobile } = useMediaQueries();
  // i18n
  const { t } = useTranslation('common');



  const { data: sitesByRegion } = useGetSitesByRegionQuery();

  const {
    features,
    hero,
    itinerantsHeader,
    locationsHeader,
    logos,
    sitesHeader,
    temporariesHeader,
    topSalesHeader,
    servicesHeader,
    seo,
    inEvidence,
  } = data;



  console.log(sitesByRegion);

  return (
    <>
      <NextSeo
        title={
          isVisible
            ? `Aditus | ${t('site.back_soon')} 🤗`
            : 'Aditus | ' + seo?.metaTitle
        }
        description={seo?.metaDescription}
        canonical="https://aditusculture.com"
        additionalMetaTags={[
          {
            name: 'keywords',
            content: seo?.keywords?.toString() || [],
          },
        ]}
        openGraph={{
          url: 'https://aditusculture.com',
          title: seo?.metaTitle,
          description: seo?.metaDescription,
          site_name: 'Aditus Culture',
          images: [
            {
              url: 'https://storage.googleapis.com/aditus-bucket-strapi/DSC_0163_min_504d03b2da/DSC_0163_min_504d03b2da.jpeg',
              width: 1200,
              height: 630,
              alt: 'Aditus Logo',
              type: 'image/jpeg',
            },
          ],
        }}
      />
      <DefaultLayout
        isHome={true}
        dense={false}
        showNewsletter={true}
        data={data}
        mobileFixedCta={hero?.cta}
        mobileFixedBtn={() => {
          const btn = document?.querySelector('#quick-buy-btn');
          if (isMobile && btn != null) return btn?.click();
          return null;
        }}
        head={
          <>
            <Hero
              type="video"
              align="center"
              titleSize="large"
              showBorder={false}
              caption={hero?.caption}
              subtitle={!isMobile && hero?.subtitle}
              posterVideo={
                hero?.poster?.data?.attributes?.large?.url
              }
              video={hero?.media?.data?.attributes?.url}
              title={
                logged
                  ? `${t('site.greeting')} ${first_name}!`
                  : hero?.title
              }
              cta={
                <QuickBuyButton
                  label={hero?.cta}
                  className="flex w-48 uppercase bg-white rounded-sm text-secondary"
                />
              }
            />
            <QuickTypeLinks />
          </>
        }
      >
        <div className="container flex flex-col px-8 mx-auto lg:px-10">
          <Features list={features?.list} />
          <ProductsReminder />
        </div>

        <InEvidence {...inEvidence} />

        <div className="container flex flex-col px-8 mx-auto lg:px-10">
          <SiteGallery
            query={
              !isMobile
                ? 'populate[4]=itinerants&populate[5]=temporaries&populate[6]=school_activities'
                : ''
            }
            {...sitesHeader}
            options={{ arrows: true }}
          />

          <LocationsOnMap {...locationsHeader} dense={true} />
          <ExclusiveGallery {...temporariesHeader} />
          <BestSellers {...topSalesHeader} />
          <ItinerantsGallery
            {...itinerantsHeader}
            options={{ arrows: false, perPage: 3 }}
          />
          <B2BServices {...servicesHeader} />
          <BlogPreview articles={articles} />

          <TagsCloud />
          <Destinations
            dense={true}
            title={t('sections.destinations.title')}
            label={t('sections.destinations.label')}
          />
          <TalkedAboutUsLogos list={logos?.data} />
        </div>
        <DrawerQuickBuy />
      </DefaultLayout>
    </>
  );
};

export async function getServerSideProps({ res, locale }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );
  const path = process.env.NEXT_PUBLIC_STRAPI;
  const qsHome = stringify(
    {
      populate: [
        'features.list.icon',
        'hero.media',
        'locationsHeader',
        'itinerantsHeader',
        'temporariesHeader',
        'sitesHeader',
        'topSalesHeader',
        'servicesHeader',
        'seo',
        'inEvidence.cta',
        'inEvidence.img',
        'logos',
      ],
      locale,
    },
    { encodeValuesOnly: true },
  );

  // DATA
  const getHomePage = await fetch(`${path}/home-page?${qsHome}`);
  const homePage = await getHomePage.json();

  // ARTICLES
  const qsArticle = stringify(
    {
      populate: ['article_categories', 'img.media'],
      sort: 'publishedAt:desc',
      locale,
    },
    { encodeValuesOnly: true },
  );

  // --- Ranking
  const getRankingArticles = await fetch(
    `${path}/article-rankings?${qsArticle}`,
  );

  // --- Basic
  const getBasicArticles = await fetch(
    `${path}/article-basics?${qsArticle}`,
  );

  // --- Itinerary
  const getItineraryArticles = await fetch(
    `${path}/article-itineraries?${qsArticle}`,
  );

  const rankingArticles = await getRankingArticles.json();
  const basicArticle = await getBasicArticles.json();
  const itineraryArticle = await getItineraryArticles.json();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: homePage?.data?.attributes || {},
      articles: [
        ...basicArticle?.data,
        ...rankingArticles?.data,
        ...itineraryArticle?.data,
      ],
    },
  };
}

export default HomePage;
